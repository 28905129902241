import React from 'react'
import { map, get } from 'lodash'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Vertical, TitleComponent } from '../commons'

const ContainerTitle = styled.div`
  padding: 0px 16px;
  padding-top: 10px;
`

const LinkWithCheck = ({ stock, to, settingFields, description, ...props }) =>
  (settingFields &&
    (get(stock, 'unlimited') || get(stock, 'stockNumber') >= 0)) ||
  (description && description.length > 60) ? (
    <Link to={to} {...props} />
  ) : (
    <span {...props} />
  )

export const SimpleList = ({ data, theme, children }) => (
  <div>
    <ContainerTitle>
      <TitleComponent theme={theme} title={data.title} />
    </ContainerTitle>
    {map(data?.products, (product) => (
      <LinkWithCheck
        style={{
          textDecoration: 'none'
        }}
        description={product.description}
        stock={get(product, 'stock')}
        settingFields={
          get(product, 'settingFields') &&
          get(product, 'settingFields').length > 0
        }
        to={{
          pathname: '/product',
          state: { product }
        }}
      >
        <Vertical
          theme={theme}
          read
          key={product.id}
          product={product}
          children={children}
        />
      </LinkWithCheck>
    ))}
  </div>
)
