import React from 'react'
import styled from 'styled-components'
import Dinero from 'dinero.js'

const Price = styled.div<any>`
  color: ${(props) => props.color.hex};
  border: 1px solid ${(props) => props.color.hex};
  background: ${(props) => props.backgroundPrice.hex};
  border-radius: 10px;
  padding-left: 5px;
  padding-right: 15px;
`
function toEuro(dinero: Dinero.Dinero) {
  return dinero.setLocale('de-DE').toFormat()
}

export function numberToEuro(amount: number) {
  if (!amount) return ''
  return toEuro(Dinero({ amount, currency: 'EUR' }))
}
export const PriceComponent = ({ price, color }) => {
  return <Price color={color}>{numberToEuro(parseInt(price, 10))}</Price>
}
