import React from 'react'
import { get } from 'lodash'
import { CardOffer } from '../commons'
import { colors } from '../helpers'

export const Offer = ({
  name,
  tva,
  price,
  stock,
  read,
  id,
  picture,
  settingFields,
  description,
  width,
  theme,
  children
}) => (
  <CardOffer
    withTheme
    width={width}
    read={read}
    tva={tva}
    name={name}
    stock={stock}
    picture={picture}
    id={id}
    description={description}
    settingFields={settingFields}
    price={price}
    theme={theme}
    children={children}
    imageHeight='30vh'
    backgroundColor={colors[get(theme, 'color.background')]}
  />
)
