import styled from 'styled-components'

export const Horizontal = styled.div<any>`
  background-color: ${(props) => props.backgroundColor};
  box-shadow: ${(props) => props.shadow};
  padding-left: 6px;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  ::-webkit-scrollbar {
    display: none;
  }
`
