import React from 'react'
import styled from 'styled-components'
import { IonSegmentButton } from '@ionic/react'
import { get } from 'lodash'
import { colors } from '../helpers'

const WrapperSegment = styled.div`
  background-color: ${(props) => props.color};
  padding: 3px;
`

const IonSegmentButtonWrapper = styled(IonSegmentButton)`
  --indicator-color-checked: ${(props) => props.selectedColor};
  --padding-end: 0;
  --padding-start: 0;
  --indicator-height: 0;
  --ripple-color: ${(props) => props.selectedColor};
  min-width: 10px !important;
  :focus {
    outline-width: 0;
  }
`

const Indicator = styled.div<any>`
  height: ${(props) => (props.currentCategory ? '2px' : '0')};
  border-radius: 11px;
  background: ${(props) => props.selectedColor};
  width: ${(props) => (props.currentCategory ? '100%' : '0%')};
  transition: width 0.5s;
`

const Containerindicator = styled.div`
  margin-top: 3px;
  width: 100%;
  height: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ImageBackground = styled.div<any>`
  background-repeat: no-repeat;
  background-image: url(${(props) => props.picture});
  background-size: cover;
  background-position: 50%;
  opacity: 1;
  height: 12vh;
  width: 12vh;
  max-width: 100px;
  max-height: 100px;
  border-radius: 4px;
`

const FilterShortcut = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  padding: 0px 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.08);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(50%, transparent),
    to(rgba(0, 0, 0, 0.16))
  );
  background: linear-gradient(180deg, transparent 50%, rgba(0, 0, 0, 0.16));
`

const CardTitle = styled.p`
  color: white;
  font-weight: bold;
  font-size: 0.9em;
  text-transform: capitalize;
  margin: 0.1em 0;
  white-space: break-spaces;
`

export const CardCategory = ({
  name,
  picture,
  id,
  currentCategory,
  theme,
  onClick
}) => (
  <WrapperSegment color={colors[get(theme, 'color.backgroundHeader')]}>
    <IonSegmentButtonWrapper
      mode='md'
      onClick={() => onClick({ id, name })}
      key={id}
      value={id}
      selectedColor={colors[get(theme, 'color.backgroundHeader')]}
    >
      <ImageBackground picture={picture}>
        <FilterShortcut>
          <CardTitle>{name}</CardTitle>
        </FilterShortcut>
      </ImageBackground>
      <Containerindicator>
        <Indicator
          selectedColor={colors[get(theme, 'color.title')]}
          currentCategory={id === currentCategory}
        />
      </Containerindicator>
    </IonSegmentButtonWrapper>
  </WrapperSegment>
)
