import React from 'react'
import styled from 'styled-components'
import { IonSegmentButton } from '@ionic/react'
import { get } from 'lodash'
import { colors } from '../helpers'

const WrapperSegment = styled.div`
  background-color: ${(props) => props.color};
  padding: 3px 10px;
  margin: -2px;
`

const IonSegmentButtonWrapper = styled(IonSegmentButton)`
  --indicator-color-checked: ${(props) => props.selectedColor};
  --padding-end: 0;
  --padding-start: 0;
  --indicator-height: 0;
  --ripple-color: ${(props) => props.selectedColor};
  min-width: 10px !important;
  :focus {
    outline-width: 0;
  }
`

const Indicator = styled.div<any>`
  height: 2px;
  margin-top: 3px;
  border-radius: 11px;
  background: ${(props) => props.selectedColor};
  width: ${(props) => (props.currentCategory ? '100%' : '0%')};
  transition: width 0.5s;
`

const Containerindicator = styled.div`
  margin-top: 3px;
  width: 100%;
  height: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const CardCategory = ({ name, id, currentCategory, theme }) => (
  <WrapperSegment color={colors[get(theme, 'color.backgroundHeader')]}>
    <IonSegmentButtonWrapper
      mode='md'
      key={id}
      value={id}
      selectedColor={colors[get(theme, 'color.backgroundHeader')]}
    >
      <span style={{ color: colors[get(theme, 'color.title')] }}>{name}</span>
      <Containerindicator>
        <Indicator
          selectedColor={colors[get(theme, 'color.title')]}
          currentCategory={id === currentCategory}
        />
      </Containerindicator>
    </IonSegmentButtonWrapper>
  </WrapperSegment>
)
