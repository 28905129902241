import React from 'react'
import { IonCard, IonText } from '@ionic/react'
import styled from 'styled-components'
import { get, noop } from 'lodash'

import '@ionic/react/css/core.css'

import { SubTitleComponent, TitleComponent } from '../commons/text'

const WrapperA = styled.a<any>`
  display: flex;
  margin: ${(props) => (props.full ? 0 : '16px')};
  border-radius: ${(props) => (props.full ? 0 : '5px')};
  overflow: hidden;
`

const WrapperDiv = styled.div<any>`
  display: flex;
  margin: ${(props) => (props.full ? 0 : '16px')};
  border-radius: ${(props) => (props.full ? 0 : '5px')};
  overflow: hidden;
`

const CardImage = styled.img`
  position: relative;
`

const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  padding-top: 16px;
  padding-bottom: 5px;
`

const Card = styled(IonCard)`
  box-shadow: none;
  width: auto;
  padding: 0;
  margin: 0;
  border-radius: 0;
`

const Link = ({ link, dev, full, onClick, ...props }) =>
  link && !dev ? (
    <WrapperA full={full} href={link} onClick={onClick} {...props} />
  ) : (
    <WrapperDiv full={full} {...props} />
  )

export const Image = ({
  title = null,
  description = null,
  picture = null,
  link = null,
  theme = null,
  dev = false,
  full = false,
  onClick = noop
}) => (
  <Card color={get(theme, 'color.background')}>
    {(title || description) && (
      <ContainerText>
        <IonText>
          <TitleComponent theme={theme} title={title} />
        </IonText>
        <IonText>
          <SubTitleComponent theme={theme} title={description} />
        </IonText>
      </ContainerText>
    )}
    <Link full={full} dev={dev} link={link} onClick={onClick}>
      <CardImage
        style={{ width: '100%', height: '100%' }}
        src={picture}
        alt='Buildings with tiled exteriors, lit by the sunset.'
      />
    </Link>
  </Card>
)
