import React from 'react'
import { IonCard, IonCardHeader, IonText } from '@ionic/react'
import styled from 'styled-components'
import '@ionic/react/css/core.css'
import YouTube from 'react-youtube'

import { get, noop } from 'lodash'
import { SubTitleComponent, TitleComponent } from '../commons/text'

const IonCardHeaderwrapper = styled(IonCardHeader)`
  width: ${(props) => props.width};
  width: auto;
  padding-top: 56.25%;
  position: relative;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
  }
`

const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 0px;
  padding: 16px;
`

const Card = styled(IonCard)`
  box-shadow: none;
  width: auto;
  margin: 0;
  margin-top: 8px;
`

const playerVars = {
  enablejsapi: 0,
  origin: window.location.origin,
  showinfo: 0,
  controls: 0
}

const opts = {
  height: '100%',
  width: '100%',
  playerVars
}

export const Video = ({
  title = null,
  description = null,
  video = null,
  launchAtStart = true,
  theme = null,
  onPlay = noop
}) => {
  const onReady = (event) => {
    if (launchAtStart) {
      event.target.playVideo()
    }
  }

  const onEnd = (event) => {
    if (launchAtStart) {
      event.target.seekTo(0)
      event.target.pauseVideo()
    }
  }

  return (
    <Card color={get(theme, 'color.background')}>
      {(title || description) && (
        <ContainerText>
          <IonText>
            <TitleComponent theme={theme} title={title} />
          </IonText>
          <IonText>
            <SubTitleComponent theme={theme} title={description} />
          </IonText>
        </ContainerText>
      )}
      <IonCardHeaderwrapper>
        <YouTube
          onPlay={onPlay}
          videoId={video}
          opts={opts}
          onReady={onReady}
          onEnd={onEnd}
        />
      </IonCardHeaderwrapper>
    </Card>
  )
}
