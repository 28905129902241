const List = 'simpleList'
const Product = 'product'
const Separator = 'separator'
const Carousel = 'carousel'
const Category = 'category'
const GalleryCategory = 'galleryCategory'
const GalleryCategoryInline = 'galleryCategoryInline'
const Image = 'image'
const Slide = 'slide'
const Video = 'video'
const Wheel = 'wheel'
const Social = 'social'
const Offer = 'offer'
const FlappyBird = 'flappyBird'

export const type = {
  GalleryCategoryInline,
  List,
  Product,
  Separator,
  Carousel,
  Category,
  Video,
  Image,
  Offer,
  Wheel,
  Slide,
  GalleryCategory,
  Social,
  FlappyBird
}
