import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'
import { IonCardTitle } from '@ionic/react'

const TitleText = styled(IonCardTitle)`
  font-weight: bold;
  font-size: 1.1em;
`

const SubTitleText = styled(IonCardTitle)`
  font-weight: 400;
  font-size: 1em;
  padding: 2px 0px;
`

export const formatDescription = (v) => {
  if (typeof v === 'string' && v.length > 60)
    v = `${v.replace(/^(.{60}[^\s]*).*/, '$1')} ...`
  return v
}

export const TitleComponent = ({ title, theme = null }) => (
  <TitleText color={get(theme, 'color.title')}>{title}</TitleText>
)

export const SubTitleComponent = ({ title, theme = null }) => (
  <SubTitleText color={get(theme, 'color.subtitle')}>{title}</SubTitleText>
)

export const SubTitleProduct = ({ title, theme = null }) => (
  <SubTitleText color={get(theme, 'color.productDescription')}>
    {formatDescription(title)}
  </SubTitleText>
)

export const TitleProduct = ({ title, theme = null }) => (
  <TitleText color={get(theme, 'color.productTitle')}>{title}</TitleText>
)
