import React from 'react'
import useGetWeather from './useGetWeather'
import styled from 'styled-components'
import img1 from './assets/01d.svg'
import img2 from './assets/01n.svg'
import img3 from './assets/02d.svg'
import img4 from './assets/02n.svg'
import img5 from './assets/03d.svg'
import img6 from './assets/04d.svg'
import img7 from './assets/09d.svg'
import img10 from './assets/11d.svg'
import img11 from './assets/13d.svg'
import img12 from './assets/50d.svg'

export function Weather() {
  const title = 'Météo 🌞'
  const data: any = useGetWeather()
  const sevenHours = data?.hourly.slice(0, 7)

  const temperaturesFor7Hours = sevenHours?.map((weatherHour) => {
    let hour = new Date(weatherHour.dt * 1000).getHours() + 3

    return (
      <ContainerWeather key={hour}>
        <CustomP>{hour}h</CustomP>
        <CustomImg
          src={getSrcFromHours(data?.hourly[hour].weather[0].icon)}
          alt='image météo'
        />
        <CustomP>{weatherHour.temp}°C</CustomP>
      </ContainerWeather>
    )
  })

  function getSrcFromHours(hour) {
    switch (hour) {
      case '01d':
        return img1

      case '01n':
        return img2

      case '02d':
        return img3

      case '02n':
        return img4

      case '03d':
        return img5

      case '03n':
        return img5

      case '04d':
        return img6

      case '04n':
        return img6

      case '09d':
        return img7

      case '09n':
        return img7

      case '10d':
        return img7

      case '10n':
        return img7

      case '11d':
        return img10

      case '11n':
        return img10

      case '13d':
        return img11

      case '13n':
        return img11

      case '50d':
        return img12

      case '50n':
        return img12

      default:
        return img1
    }
  }

  return (
    <div>
      <h2>{title}</h2>
      <CustomDiv>{data && temperaturesFor7Hours}</CustomDiv>
    </div>
  )
}

const CustomP = styled.p`
  display: flex;
  justify-content: center;
  font-size: 12px;
  color: rgb(35, 32, 30);
`

const CustomDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 100%;
`

const ContainerWeather = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #CECECE;
`

const CustomImg = styled.img`
  display: flex;
  width: 100%;
`
