import React from 'react'
import styled from 'styled-components'
import { get, noop } from 'lodash'

const Container = styled.div<any>`
  background-color: ${(props) => props.background};
  height: 5em;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const Social = styled.a`
  margin: 1em 2em;
`

const SocialImg = styled.img`
  height: 2em;
  width: 2em;
`

export const SocialMedia = ({
  instagram,
  facebook,
  twitter,
  theme,
  onClick = noop
}) => {
  return (
    <Container background={get(theme, 'color.background')}>
      {facebook && (
        <Social
          onClick={() => onClick('facebook')}
          key={facebook}
          href={facebook}
          target='blank'
        >
          <SocialImg src='/img/icon/facebook.svg' />
        </Social>
      )}
      {twitter && (
        <Social
          onClick={() => onClick('twitter')}
          key={twitter}
          href={twitter}
          target='blank'
        >
          <SocialImg src='/img/icon/twitter.svg' />
        </Social>
      )}
      {instagram && (
        <Social
          onClick={() => onClick('instagram')}
          key={instagram}
          href={instagram}
          target='blank'
        >
          <SocialImg src='/img/icon/instagram.svg' />
        </Social>
      )}
    </Container>
  )
}
