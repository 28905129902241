import React from 'react'
import { IonSlides, IonSlide } from '@ionic/react'
import { map, get } from 'lodash'
import styled from 'styled-components'
import '@ionic/react/css/core.css'
import { colors } from '../helpers'
import { SmartImage } from '../commons/smartImage'

const IonSlidesWrapper = styled(IonSlides)`
  --bullet-background-active: ${(props) => props.bulletColor};
`

const slideOpts = {
  initialSlide: 0,
  speed: 400,
  autoplay: {
    delay: 5000
  }
}

export const ImageSlides = ({ data, theme }) => {
  return (
    <IonSlidesWrapper
      key={data.pictures.join('')}
      bulletColor={colors[get(theme, 'color.title')]}
      pager
      options={slideOpts}
    >
      {map(data.pictures, (picture) => {
        return (
          <IonSlide>
            <SmartImage key={picture} picture={picture} imgSrc={picture} />
          </IonSlide>
        )
      })}
    </IonSlidesWrapper>
  )
}
