import React from 'react'
import { map, get, noop } from 'lodash'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { colors } from '../helpers'
import {
  TitleComponent,
  SubTitleComponent,
  CardCategory,
  Horizontal
} from '../commons'

const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5% 0 0 16px;
`

const LinkWithCheck = ({ stock, to, settingFields, description, ...props }) =>
  (settingFields &&
    (get(stock, 'unlimited') || get(stock, 'stockNumber') >= 0)) ||
  (description && description.length > 60) ? (
    <Link to={to} {...props} />
  ) : (
    <span {...props} />
  )

export const Carousel = ({
  data,
  width,
  read = false,
  theme,
  children,
  onScroll = noop,
  linkState = {}
}) => (
  <div>
    <Header>
      <TitleComponent theme={theme} title={data.title} />
      <SubTitleComponent theme={theme} title={data.description} />
    </Header>
    <Horizontal onScroll={onScroll}>
      {map(data.products, (value) => (
        <div key={value.imageId} style={{ flex: '0 0 auto' }}>
          <LinkWithCheck
            style={{
              textDecoration: 'none'
            }}
            settingFields={
              get(value, 'settingFields') &&
              get(value, 'settingFields').length > 0
            }
            stock={get(value, 'stock')}
            description={value.description}
            to={{
              pathname: '/product',
              state: { ...linkState, product: value }
            }}
          >
            <CardCategory
              withTheme
              width={width}
              read={read}
              isShadow
              margin='10px'
              stock={value.stock}
              name={value.reference}
              tva={value.tva}
              picture={value.picture}
              id={value.id}
              settingFields={value.settingFields}
              description={value.description}
              price={value.price}
              theme={theme}
              imageHeight='20vh'
              children={children}
              backgroundColor={colors[get(theme, 'color.productBackground')]}
            />
          </LinkWithCheck>
        </div>
      ))}
    </Horizontal>
  </div>
)
