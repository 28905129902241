import React from 'react'
import { IonCard, IonCardHeader, IonText } from '@ionic/react'
import styled from 'styled-components'
import { get } from 'lodash'
import '@ionic/react/css/core.css'

import { colors } from '../helpers/colors'
import { SubTitleProduct, TitleProduct } from './text'
import { SmartImage } from './smartImage'
import { numberToEuro } from './price'

export const CardCategory = ({
  name,
  price,
  stock,
  settingFields = null,
  read = false,
  id = null,
  tva = null,
  margin = '0',
  picture = null,
  description = null,
  isShadow = false,
  width = null,
  theme = null,
  children,
  withTheme = false,
  imageHeight = '100%',
  backgroundColor = null
}) => (
  <IonCardShadow
    withTheme={withTheme}
    theme={theme}
    key={id}
    isShadow={isShadow}
    width={width}
    margin={margin}
    backgroundColor={backgroundColor}
  >
    <IonCardHeaderwrapper>
      <SmartImage height={imageHeight} imgSrc={picture} />
      <Price
        color={colors[get(theme, 'color.productPrice')]}
        bgColor={colors[get(theme, 'color.backgroundPrice')]}
      >
        {numberToEuro(parseInt(price, 10))}
      </Price>
    </IonCardHeaderwrapper>
    <ContainerText>
      <IonText>
        <TitleProduct theme={theme} title={name} />
      </IonText>
      <IonText>
        <SubTitleProduct theme={theme} title={description} />
      </IonText>
      {read &&
        React.cloneElement(children, {
          id,
          reference: name,
          price,
          stock,
          tva,
          settingFields,
          description
        })}
    </ContainerText>
  </IonCardShadow>
)

const IonCardHeaderwrapper = styled(IonCardHeader)`
  width: auto;
  max-height: max-content;
  padding: 16px;
`

const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 5px;
  padding-bottom: 5px;
`

const Price = styled.p<any>`
  position: absolute;
  right: 2em;
  color: ${(props) => props.color};
  bottom: -5px;
  font-weight: 500 !important;
  background-color: ${(props) => props.bgColor};
  border-radius: 20px;
  padding: 7px 23px;
  font-size: 14px;
  box-shadow: 0px 0px 20px 0px rgba(10, 10, 10, 0.15);
`

const IonCardShadow = styled(IonCard)`
  background-color: ${(props) =>
    props.withTheme ? props.backgroundColor : ''};
  box-shadow: ${({ isShadow }) =>
    isShadow ? '0px 0px 20px 0px rgba(100,100,100,.15)' : 'none'};
  width: ${({ width }) => width || 'auto'};
  margin-top: 8px;
  padding: 0;
  margin: ${(props) => props.margin};
`
