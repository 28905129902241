import React from "react";
import useGetWeather from "../Weather/useGetWeather";
import getProductsOnWeather from "./getProductsOnWeather";
import styled from "styled-components";

export function SuggestionsOnWeather() {
  const title = "Suggestions en fonction de la météo 🍔";
  const data = useGetWeather();
  const products = getProductsOnWeather(data?.current.weather[0].description);

  return (
    <CustomWrapper>
      <h2>{title}</h2>
      <p>Au vu de la météo, nous vous recommandons : </p>
      {products?.map((product, i) => {
        return (
          <div key={i}>
            <span>{product?.name}</span>
            <span>{product?.isSunny} </span>
          </div>
        );
      })}
    </CustomWrapper>
  );
}
const CustomWrapper = styled.div``;
