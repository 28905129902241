// @ts-nocheck
import { PhaserGame } from './game'
import styled from 'styled-components'
import React, { useEffect, useState } from 'react'
import { IonIcon, IonModal, IonButton, IonInput } from '@ionic/react'
import { playOutline } from 'ionicons/icons'
import './index.css'

const IonInputWrapper = styled(IonInput)`
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: 23px;
  background: white;
  margin-bottom: 2.5em;
`

const IonModalGameWrapper = styled(IonModal)`
  canvas {
    z-index: 1000;
  }
`

const IonModalEndWrapper = styled(IonModal)`
  --background: transparent;
  align-items: flex-end;

  ion-backdrop {
    z-index: 100;
  }

  .modal-wrapper {
    height: 10vh;
    z-index: 110;
  }
`

const IonModalScoreWrapper = styled(IonModal)`
  --background: transparent;
  align-items: flex-end;

  ion-backdrop {
    z-index: 100;
    background: rgba(0, 0, 0, 0.1);
  }

  .modal-wrapper {
    height: 44vh;
    z-index: 110;
  }

  .ion-page {
    align-items: center;
    justify-content: space-around;
  }
`

const ContainerButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
const ContainerInputScore = styled.div`
  width: 96%;
  height: 30vh;
  background-image: url(/assets/EndGame.png);
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`

const ContainerCard = styled.div`
  width: 100%;
  height: 30vh;
  background-image: url(/assets/playMe.png);
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`

const ContainerResumeScore = styled.div`
  width: 50%;
  height: 4em;
  margin-top: 0.5em;
  background-image: url(/assets/flappyBird.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const FlappyBirdComponent = ({
  edit,
  background = 'assets/background-night.png',
  onEnd,
  hightScore,
  children
}) => {
  const [showGame, setShowGame] = useState(false)
  const [endGame, setEndGame] = useState(false)
  const [openHightScoreModal, setOpenHightScoreModal] = useState(false)
  const [name, setName] = useState('')
  const [score, setHightScore] = useState('')

  const onEndFinish = (score) => {
    console.log('ON END', score, hightScore.score)
    if (score > hightScore.score) {
      setOpenHightScoreModal(true)
      setEndGame(true)
      setHightScore(score)
    } else {
      setOpenHightScoreModal(false)
      setEndGame(true)
    }
  }

  useEffect(() => {
    if (showGame) {
      PhaserGame({ background, onEnd: onEndFinish })
    }
  }, [showGame])

  return (
    <div style={{ position: 'relative' }}>
      <IonModalGameWrapper
        isOpen={showGame}
        showBackdrop={false}
        backdropDismiss={false}
        onDidDismiss={(e) => console.log('e')}
        onWillDismiss={(e) => console.log('e')}
        id='phaser-example'
        cssClass='ion-modal-game'
      />
      <IonModalScoreWrapper
        backdropDismiss
        onDidDismiss={() => setEndGame(false)}
        isOpen={endGame && openHightScoreModal}
        showBackdrop={false}
      >
        <ContainerInputScore>
          <div style={{ display: 'flex', width: '90%' }}>
            <IonInputWrapper
              value={name}
              maxlength={12}
              color='dark'
              mode='md'
              onIonChange={(e) => setName(e?.detail?.value)}
              debounce={200}
              placeholder='Ajoute ton nom'
            />
          </div>
        </ContainerInputScore>
        <ContainerButton>
          <IonButton
            color='dark'
            onClick={() => {
              setOpenHightScoreModal(false)
              setShowGame(false)
              onEnd({ name, score })
            }}
          >
            <div>
              <p> VALIDER </p>
            </div>
          </IonButton>
          <IonButton
            color='dark'
            onClick={() => {
              console.log('CLICKED')
              setShowGame(false)
              setEndGame(false)
              onEnd(score)
            }}
          >
            <div>
              <p> QUITTER </p>
            </div>
          </IonButton>
        </ContainerButton>
      </IonModalScoreWrapper>
      <IonModalEndWrapper
        backdropDismiss
        onDidDismiss={() => setEndGame(false)}
        isOpen={endGame && showGame && !openHightScoreModal}
        showBackdrop={false}
      >
        <ContainerButton>
          <IonButton
            color='dark'
            onClick={() => {
              console.log('CLICKED')
              setShowGame(false)
              setEndGame(false)
              onEnd(score)
            }}
          >
            <div>
              <p> QUITTER </p>
            </div>
          </IonButton>
        </ContainerButton>
      </IonModalEndWrapper>
      <div
        onClick={() => {
          if (!edit) setShowGame(true)
        }}
      >
        {children}
      </div>
    </div>
  )
}

export const FlappyBird = ({
  edit = false,
  background = 'assets/6.jpg',
  hightScore = { name: 'Alex', score: 0 },
  updateHightScore = ({ score, name }) =>
    console.log('Update score', score, name)
}) => {
  console.log('Mount')
  return (
    <FlappyBirdComponent
      edit={edit}
      background={background}
      hightScore={hightScore}
      onEnd={updateHightScore}
    >
      <ContainerCard>
        <div
          style={{
            padding: '10px',
            height: '40px',
            width: '40px',
            borderRadius: '50%',
            background: '#222428',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            paddingBottom: '.5em'
          }}
        >
          <IonIcon
            icon={playOutline}
            style={{ fontSize: '1.5em', fontWeight: 500, color: 'white' }}
          />
        </div>
        <ContainerResumeScore>
          <div
            style={{
              display: 'flex',
              color: 'black',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '15px'
            }}
          >
            <p style={{ fontWeight: 'bold', margin: '0', marginRight: '15px' }}>
              {hightScore.name}
            </p>
            <p style={{ fontWeight: 'bold', margin: '0' }}>
              {hightScore.score}
            </p>
          </div>
        </ContainerResumeScore>
      </ContainerCard>
    </FlappyBirdComponent>
  )
}
