import React from 'react'
import { IonItem } from '@ionic/react'
import styled from 'styled-components'

const ItemBar = styled(IonItem)`
  height: 8px;
  width: 100%;
  margin: 2em 0;
`

export const Separator = ({ data }) => {
  return <ItemBar color={data.color} />
}
