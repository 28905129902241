import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'
import { CardCategory } from './CardTitleOverImage'
import { colors } from '../helpers'
import { IonContent } from '@ionic/react'

export const CategoriesGallery = ({
  theme,
  data,
  currentCategory,
  setCurrentCategory,
  components
}) => {
  return (
    <div>
      {currentCategory ? (
        components()
      ) : (
        <IonContent fullscreen>
          <WrapperCategories
            color={colors[get(theme, 'color.background')]}
            scrollable
            mode='md'
          >
            {data.map((value) => (
              <CardCategory
                theme={theme}
                key={value.id}
                setCurrentCategory={setCurrentCategory}
                id={value.id}
                name={value.name}
                picture={value.picture}
              />
            ))}
          </WrapperCategories>
        </IonContent>
      )}
    </div>
  )
}

const WrapperCategories = styled.div<any>`
  flex-wrap: wrap;
  align-items: center;
  background-color: ${(props) => props.color};
  overflow-y: hidden;
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  height: 90%;
  overflow: scroll;
  justify-content: space-evenly;
  align-content: flex-start;
`
