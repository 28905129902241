import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { get, map } from 'lodash'
import {
  IonModal,
  IonLabel,
  IonButton,
  IonIcon,
  IonInput,
  IonList
} from '@ionic/react'
import '@ionic/react/css/core.css'
import { closeCircleOutline, addCircle } from 'ionicons/icons'
import colorPalette from 'google-palette'
import { TitleComponent, SubTitleComponent } from '../commons'
import { colors } from '../helpers'

export const WheelComponent = ({ data, theme }) => {
  const [selectedItem, setSelectedItem] = useState(0)
  const [spinning, setSpinning] = useState(false)
  const [displayList, setDisplayList] = useState(false)
  const [items, setItems] = useState(['Thomas', 'Sarah', 'Mathieu', 'Coline'])
  const [palette, setPalette] = useState(colorPalette('cb-RdYlGn', 4))

  const selectItem = () => {
    setSelectedItem(Math.floor(Math.random() * items.length))
  }

  useEffect(() => {
    if (selectedItem == null) setSpinning(false)
    if (selectedItem !== null) setSpinning(true)
  }, [selectedItem])

  return (
    <MainContainer>
      <Header>
        <TitleComponent theme={theme} title={data.title} />
        <SubTitleComponent theme={theme} title={data.description} />
      </Header>
      <WheelContainer>
        <IonIconWheel
          onClick={() => setDisplayList(true)}
          bgColor={colors[get(theme, 'color.background')]}
          color={get(theme, 'color.title')}
          icon={addCircle}
        />
        <Root>
          <WheelContent
            selectorColor={colors[get(theme, 'color.title')]}
            bgColor={colors[get(theme, 'color.background')]}
          >
            <Wheel
              selectorColor={colors[get(theme, 'color.title')]}
              bgColor={colors[get(theme, 'color.background')]}
              spinning={spinning}
              style={{
                '--item-nb': items.length,
                '--selected-item': selectedItem
              }}
              itemsNumber={items.length}
              selectedItem={selectedItem}
            >
              {map(items, (item, index) => (
                <WheelItem
                  itemsNumber={items.length}
                  key={index}
                  style={{ '--item-nb': index }}
                  colorItems={palette[index]}
                >
                  <WheelWrapperLabel>
                    <IonLabel
                      style={{ fontWeight: 500, fontSize: '1.1em' }}
                      color='white'
                    >
                      {item && item.length > 8 && `${item.substring(0, 8)}..`}
                      {item && item.length <= 8 && item}
                    </IonLabel>
                  </WheelWrapperLabel>
                </WheelItem>
              ))}
            </Wheel>
          </WheelContent>
        </Root>
      </WheelContainer>
      <IonButtonStart
        color={get(theme, 'color.title')}
        onClick={() => (selectedItem ? setSelectedItem(0) : selectItem())}
      >
        {selectedItem ? 'RELANCER' : 'LANCER'}
      </IonButtonStart>
      <ModalName
        theme={theme}
        displayList={displayList}
        setDisplayList={setDisplayList}
        items={items}
        setItems={setItems}
        setPalette={setPalette}
        titleModal={data.titleModal}
      />
    </MainContainer>
  )
}

const ModalName = ({
  items,
  setItems,
  displayList,
  setDisplayList,
  setPalette,
  theme,
  titleModal
}) => {
  const [value, setValue] = useState('')
  return (
    <IonModalWrapper
      isOpen={displayList}
      bgColor={colors[get(theme, 'color.background')]}
      onDidDismiss={() => setDisplayList(false)}
      showBackdrop
    >
      <IonModalContent bgColor={colors[get(theme, 'color.background')]}>
        <div style={{ width: '76%', textAlign: 'center' }}>
          <TitleComponent theme={theme} title={titleModal} />
        </div>
        <ModalBottomContent>
          <ListItemName bgColor={colors[get(theme, 'color.background')]}>
            {map(items, (name, index) => (
              <ItemName
                color={colors[get(theme, 'color.title')]}
                key={Math.random()}
              >
                <IonLabel style={{ fontSize: '1.1em', fontWeight: '400' }}>
                  {name}
                </IonLabel>
                <IonIcon
                  onClick={() => {
                    items.splice(index, 1)
                    setItems([...items])
                  }}
                  icon={closeCircleOutline}
                  style={{ fontSize: '1.5em', fontWeight: 500 }}
                />
              </ItemName>
            ))}
          </ListItemName>
          <ModalWrapperInput>
            <IonInputWrapper
              color={get(theme, 'color.title')}
              value={value}
              mode='md'
              onIonChange={(e) => setValue(get(e, 'detail.value'))}
              debounce={200}
              placeholder="'ex: Marie'"
            />
          </ModalWrapperInput>
          <ModalWrapperButton>
            {items.length < 8 && (
              <IonButton
                onClick={() => {
                  if (value.length > 0) {
                    items.push(value)
                    setItems(items)
                    setValue('')
                    setPalette(colorPalette('tol-rainbow', items.length))
                  }
                }}
                color={get(theme, 'color.title')}
              >
                Ajouter
              </IonButton>
            )}
            <IonButton
              onClick={() => setDisplayList(false)}
              color={get(theme, 'color.title')}
            >
              Fermer
            </IonButton>
          </ModalWrapperButton>
        </ModalBottomContent>
      </IonModalContent>
    </IonModalWrapper>
  )
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 1em;
  margin: 1em 0;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5% 0 0 16px;
`

const IonIconWheel = styled(IonIcon)`
  position: absolute;
  z-index: 10;
  cursor: pointer;
  width: 3em;
  height: 3em;
  background: ${(props) => props.bgColor};
  border-radius: 50%;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`

const IonButtonStart = styled(IonButton)`
  font-weight: bold;
`

/* Conteneur de la roue = cercle rose extérieur */
const WheelContainer = styled.div<any>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 1em;
  border-radius: 50%;
  border: solid ${(props) => props.bgcolor} 5px;
  overflow: hidden;
  z-index: 10000;
`

const Root = styled.div`
  overflow: hidden;
  --wheel-font: 'Lato', 'Quicksand', sans-serif;
  --wheel-size: 280px;
  --wheel-slice-spacing: 0px;
  --wheel-border-size: 5px;
  --neutral-color: lightgrey;
  --PI: 3.1416;
  --item-nb: 0;
  --selected-item: 0;
  --nb-turn: 5;
  --spinning-duration: 4s;
  --reset-duration: 0.25s;
`

const WheelContent = styled.div<any>`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: content-box;
  width: 280px;
  height: 280px;
  margin: auto;
  border-radius: 50%;
  overflow: hidden;
  user-select: none;

  /* Triangle de sélection = 1 rose +  1 blanc */
  ::before,
  ::after {
    content: '';
    display: block;
    position: absolute;
    height: 0;
    width: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    border: solid transparent 20px;
    border-left-width: 0;
  }

  ::before {
    right: 0px;
    border-right-color: ${(props) => props.bgcolor};
  }

  ::after {
    right: -5px;
    border-right-color: ${(props) => props.selectorColor};
  }
`

const WheelWrapperLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 75%;
  padding-right: 0.3em;
`

/* Roue */
const Wheel = styled.div<any>`
  margin: auto;
  width: 270px;
  height: 270px;
  border-radius: 50%;
  box-shadow: 0 0px 6px rgba(0,0,0,.2);
  /* border: solid ${(props) => props.bgColor} var(--wheel-border-size); */
  background-color: ${(props) => props.bgColor};
  transition: ${(props) =>
    props.spinning ? 'transform var(--spinning-duration)' : ''};
  transform:  ${(props) =>
    props.spinning
      ? `rotate(calc(var(--nb-turn) * 360deg + (-360deg * var(--selected-item) / ${props.itemsNumber})))`
      : ''};
  ::after {
    display: block;
    position: absolute;
    content: '';
    background-color: var(--neutral-color);
    width: 25px;
    height: 25px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }
`

/* Element sur la roue */
const WheelItem = styled.div<any>`
  display: block;
  position: absolute;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* position de l'item */
  top: 50%;
  left: 50%;
  width: 50%;
  transform-origin: center left;
  transform: ${(props) =>
    `translateY(-50%) rotate(calc(var(--item-nb) * (360deg / ${props.itemsNumber})))`};

  /* texte */
  text-align: right;
  font-family: var(--wheel-font);
  :after {
    content: ' ';
    display: block;
    position: absolute;
    box-sizing: border-box;
    z-index: -1;
    width: 0;
    height: ${(props) => {
      if (props.itemsNumber <= 3) return '280px'
      return 0
    }};
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-left: 0px;
    --slice-max-width: calc(var(--PI) * (var(--wheel-size)));
    --slice-width: ${(props) =>
      `calc(var(--slice-max-width) / ${props.itemsNumber})`};
    --final-width: ${(props) => {
      if (props.itemsNumber <= 2) return 0
      if (props.itemsNumber === 3)
        return 'calc((var(--slice-width) / 2) + 105px)'
      if (props.itemsNumber > 3 && props.itemsNumber < 6)
        return `calc((var(--slice-width) / 2) + var(--wheel-size)/ (${props.itemsNumber}*2))`
      if (props.itemsNumber >= 6) return 'calc((var(--slice-width) / 2) + 10px)'

      return true
    }};
    border: solid transparent var(--final-width);
    border-left: solid transparent 0;
    /* profondeur du triangle = du centre de la roue à la bordure = 300px / 2 */
    border-right: solid calc(var(--wheel-size) / 1.92);
    border-right-color: #${(props) => props.colorItems};
  }
`

/* Modal d'ajout de nom */
const IonModalWrapper = styled(IonModal)`
  --height: 50%;
  --width: 75%;
  --border-radius: 10px;
`

const IonInputWrapper = styled(IonInput)`
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: 23px;
  width: 100%;
`

const ItemName = styled.div`
  border-bottom: 1px solid lightgrey;
  width: 90%;
  padding: 0.2em 0;
  display: flex;
  color: ${(props) => props.color};
  align-items: center;
  justify-content: space-between;
`

const ListItemName = styled(IonList)`
  width: 80%;
  align-items: center;
  display: flex;
  background-color: ${(props) => props.bgColor};
  flex-direction: column;
  margin-bottom: 0;
  height: 22vh;
  overflow: scroll;
`

const IonModalContent = styled.div<any>`
  width: 100%;
  height: 100%;
  padding: 1em 0;
  background-color: ${(props) => props.bgColor};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
`

const ModalBottomContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`

const ModalWrapperInput = styled.div`
  width: 76%;
  margin-top: 0.5em;
`

const ModalWrapperButton = styled.div`
  width: 76%;
  margin-top: 0.5em;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`
