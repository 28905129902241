import React from 'react'
import { IonItem, IonReorder, IonRow, IonCol, IonText } from '@ionic/react'
import styled from 'styled-components'
import { get } from 'lodash'
import '@ionic/react/css/core.css'
import { numberToEuro } from '../../price'

import { formatDescription } from '../../text'

const Price = styled.div`
  font-weight: 500 !important;
  border-radius: 20px;
  border: 1px solid;
  padding: 0 6px;
  font-size: 14px;
`

const Text = styled(IonText)`
  margin: 0;
  padding: 0;
  line-height: 1em;
`

const IonItemWrapper = styled(IonItem)`
  margin-top: 0.6em;
`

const Col = styled(IonCol)`
  display: flex;
  flex: ${(props) => props.flex};
  justify-content: center;
  flex-direction: column;
  padding: 5px 0;
  align-items: ${(props) => props.alignItems};
`

export const Vertical = ({ product, read = false, theme = null, children }) => {
  const {
    reference,
    price,
    description,
    id,
    stock,
    tva,
    settingFields,
    disponibility
  } = product
  return (
    <IonItemWrapper color={get(theme, 'color.background')} lines='none'>
      <IonReorder slot='end' />
      <Col alignItems='flex-start' flex='0.75'>
        <IonRow>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              paddingBottom: '3px'
            }}
          >
            <Text
              style={{ marginRight: '10px', fontWeight: '500' }}
              color={get(theme, 'color.productTitle')}
            >
              {reference}
            </Text>
            <Text
              style={{ fontWeight: '500', whiteSpace: 'nowrap' }}
              color={get(theme, 'color.productTitle')}
            >
              <Price>{numberToEuro(parseInt(price, 10))}</Price>
            </Text>
          </div>
        </IonRow>
        <IonRow>
          <Text color={get(theme, 'color.productDescription')}>
            {formatDescription(description)}
          </Text>
        </IonRow>
      </Col>
      <Col alignItems='flex-end' flex='0.25'>
        {read &&
          React.cloneElement(children, {
            id,
            reference,
            price,
            stock,
            tva,
            settingFields,
            disponibility,
            description
          })}
      </Col>
    </IonItemWrapper>
  )
}
