import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'
import { colors } from '../helpers'

const ContainerCategorie = styled.div`
  background-color: ${(props) => props.color};
  padding: 0.5em;
  justify-content: center;
  align-items: center;
  display: flex;
`

const Categorie = styled.div`
  min-width: 10px !important;
  :focus {
    outline-width: 0;
  }
`

const ImageBackground = styled.div<any>`
  background-repeat: no-repeat;
  background-image: url(${(props) => props.picture});
  background-size: cover;
  background-position: 50%;
  opacity: 1;
  height: 40vw;
  width: 40vw;
  max-width: 150px;
  max-height: 150px;
  border-radius: 4px;
`

const FilterShortcut = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  padding: 0px 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.08);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(50%, transparent),
    to(rgba(0, 0, 0, 0.16))
  );
  background: linear-gradient(180deg, transparent 50%, rgba(0, 0, 0, 0.16));
`

const CardTitle = styled.p`
  color: white;
  font-weight: bold;
  font-size: 0.9em;
  text-transform: capitalize;
  white-space: break-spaces;
`

export const CardCategory = ({
  name,
  picture,
  id,
  setCurrentCategory,
  theme
}) => (
  <ContainerCategorie color={colors[get(theme, 'color.background')]}>
    <Categorie
      onClick={() => setCurrentCategory(id)}
      style={{ backgroundColor: colors[get(theme, 'color.background')] }}
    >
      <ImageBackground picture={picture}>
        <FilterShortcut>
          <CardTitle>{name}</CardTitle>
        </FilterShortcut>
      </ImageBackground>
    </Categorie>
  </ContainerCategorie>
)
