/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import styled from 'styled-components'
import { IonHeader, IonSegment } from '@ionic/react'
import { get, map } from 'lodash'
import { CardCategory } from './Item'
import { colors } from '../helpers'

export const CategoriesHeaderText = ({
  theme,
  data,
  currentCategory,
  setCurrentCategory
}) => {
  return (
    <Header translucent>
      <WrapperIonSegment
        color={colors[get(theme, 'color.backgroundHeader')]}
        scrollable
        mode='md'
        onIonChange={(props) => setCurrentCategory(props.detail.value)}
      >
        {map(data, (value) => (
          <CardCategory
            key={value.id}
            theme={theme}
            currentCategory={currentCategory}
            id={value.id}
            name={value.name}
          />
        ))}
      </WrapperIonSegment>
    </Header>
  )
}

const WrapperIonSegment = styled(IonSegment)`
  padding-left: 8px;
  background-color: ${(props) => props.color};
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  ::-webkit-scrollbar {
    display: none;
  }
`

const Header = styled(IonHeader)`
  box-shadow: 0px 10px 10px -7px rgba(0, 0, 0, 0.15);
  & ::after {
    height: 0;
    display: none;
  }
`
