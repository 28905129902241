import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'
import { colors } from '../helpers'

const ContainerCategorie = styled.div`
  background-color: ${(props) => props.color};
  padding: 5px 5px;
  width: 100%;
  height: 15vh;
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;
`
const CardTitle = styled.p`
  color: white;
  font-weight: bold;
  font-size: 1.5em;
  text-transform: capitalize;
  white-space: break-spaces;
`

export const CardCategory = ({
  name,
  picture,
  id,
  setCurrentCategory,
  theme
}) => (
  <ContainerCategorie
    onClick={() => setCurrentCategory(id)}
    color={colors[get(theme, 'color.background')]}
  >
    <img
      src={picture}
      style={{
        width: '100%',
        height: '90%',
        position: 'absolute'
      }}
    />
    <div
      style={{
        position: 'absolute'
      }}
    >
      <CardTitle>{name}</CardTitle>
    </div>
  </ContainerCategorie>
)
